import React from "react";
import Icon from "@ant-design/icons";

const PasswordChangedSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80.102"
    height="82.614"
    viewBox="0 0 80.102 82.614"
  >
    <g id="locked" transform="translate(-1381.688 -454.729)">
      <g
        id="Group_4937"
        data-name="Group 4937"
        transform="translate(1382.688 464.701)"
      >
        <path
          id="Path_966"
          data-name="Path 966"
          d="M15,118.37C15,108.224,22.4,100,31.533,100s16.533,8.224,16.533,18.37"
          transform="translate(-0.304 -100)"
          fill="none"
          stroke="#5a5b5e"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <rect
          id="Rectangle_988"
          data-name="Rectangle 988"
          width="62.457"
          height="44.088"
          rx="2"
          transform="translate(0 27.555)"
          fill="none"
          stroke="#5a5b5e"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <circle
          id="Ellipse_246"
          data-name="Ellipse 246"
          cx="11.022"
          cy="11.022"
          r="11.022"
          transform="translate(20.207 38.577)"
          fill="none"
          stroke="#5a5b5e"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_598"
          data-name="Line 598"
          y2="7.348"
          transform="translate(31.229 45.924)"
          fill="none"
          stroke="#5a5b5e"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
      <g
        id="Group_4938"
        data-name="Group 4938"
        transform="translate(1422.486 455)"
      >
        <circle
          id="Ellipse_281"
          data-name="Ellipse 281"
          cx="19.5"
          cy="19.5"
          r="19.5"
          transform="translate(0.305 -0.272)"
          fill="#fff"
        />
        <g
          id="Group_4936"
          data-name="Group 4936"
          transform="translate(3.749 3.761)"
        >
          <path
            id="Path_284"
            data-name="Path 284"
            d="M-60.478,201.507l-14.051,14.05a1.437,1.437,0,0,1-2.032,0l-3.992-3.992"
            transform="translate(90.214 -196.114)"
            fill="none"
            stroke="#299f5b"
            strokeLinecap="square"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_313"
            data-name="Path 313"
            d="M-71.917,195.3A15.811,15.811,0,0,0-89.37,198.63a15.8,15.8,0,0,0,0,22.352,15.8,15.8,0,0,0,22.352,0,15.772,15.772,0,0,0,4.565-12.607"
            transform="translate(94 -194)"
            fill="none"
            stroke="#299f5b"
            strokeLinecap="square"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
  </svg>
);

const PasswordChangedIcon = (props: any) => (
  <Icon component={PasswordChangedSVG} {...props} />
);

export default PasswordChangedIcon;
